<template>
	<div data-component="card-view">
		<div
			data-element="controls"
			v-if="getShowControls"
		>
			<filters
				v-if="filters"
				:filters="filters"
				@filter="onFilter"
			/>
			<search-input
				v-if="showSearch"
				:inputPlaceholder="searchPlaceholder"
				@search="onSearch"
			/>
		</div>
		<div data-element="cards">
			<component
				:is="getCardComponent"
				v-for="(card, key) in cards"
				:key="key"
				v-bind="card"
			/>
		</div>
		<records-count
			:pagination="pagination"
			:total="totalItems"
		/>
		<pagination
			v-if="showPagination"
			v-bind="pagination"
			@paginate="onPaginate"
		/>
	</div>
</template>

<script>

	import SearchInput     from '@/components/ui/views/SearchInput';
	import Filters         from '@/components/ui/views/filters/Filters';
	import CourseCard      from '@/components/courses/CourseCard';
	import Pagination      from '@/components/ui/views/Pagination';
	import RecordsCount    from '@/components/ui/views/RecordsCount';
	import { pascalCase }  from 'change-case';

	export default {
		components: {
			SearchInput,
			Filters,
			CourseCard,
			Pagination,
			RecordsCount
		},
		props: {
			cardType: {
				type: String,
				default: 'course'
			},
			showSearch: {
				type: Boolean,
				default: true
			},
			searchPlaceholder: {
				type: String,
				default: undefined
			},
			showPagination: {
				type: Boolean,
				default: true
			},
			showClearFilters: {
				type: Boolean,
				default: false
			},
			isBrowseCourses: {
				type: Boolean,
				default: false
			},
			filters: {
				type: Array,
				default: undefined
			},
			cards: {
				type: [Array, Boolean],
				required: true
			},
			pagination: {
				type: Object,
				default: undefined
			},
			totalItems: {
				type: Number,
				default: 0
			}
		},
		computed: {
			getShowControls () {
				if (!this.filters &&
						!this.showSearch) {
					return false;
				}
				return true;
			},
			getCardComponent () {
				return pascalCase(`${this.cardType}Card`);
			}
		},
		methods: {
			onSearch (value) {
				this.$emit('search', value);
			},
			onFilter (value) {
				this.$emit('filter', value);
			},
			onPaginate (value) {
				this.$emit('paginate', value);
			},
			isHighlighted (index) {
				if (!this.table.highlighted) {
					return false;
				}
				return this.table.highlighted[index];
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='card-view'] {
		@include cols(12);
		[data-element='controls'] {
			@include nested-grid;
			@include vertical-gut;
			// margin-bottom:rem(22);
			[data-component='filters'] {
				margin-bottom:rem(22);
			}
		}
		[data-element='cards'] {
			@include nested-grid;
			align-items:stretch;
			&>[data-component$='card'] {
				width:100%;
				@include vertical-nested-gut(12);
				@include mq($above: 'sm', $below: 'md') {
					@include nested-cols(6, 12);
					@include nested-gut(12);
					&:nth-of-type(2n) {
						margin-right:0;
					}
				}
				@include mq('md') {
					@include nested-cols(4, 12);
					@include nested-gut(12);
					&:nth-of-type(3n) {
						margin-right:0;
					}
				}
			}
		}
		[data-component='pagination'] {
			margin-top:12px;
		}
		[data-highlight] {
			// modify this to something better when we do UI
			opacity: 0.5;
		}
  }

</style>
