<template>
	<div data-component="experience-level-chart">
		<p
			v-if="getLabel"
			data-element="label"
		>
			{{ getLabel }}
		</p>
		<div data-element="levels">
			<experience-level-badge
				v-for="(experienceLevel, key) in getExperienceLevels"
				:key="key"
				:level="experienceLevel"
			/>
		</div>
	</div>
</template>

<script>

	import ExperienceLevelBadge from '@/components/courses/ExperienceLevelBadge';

	export default {
		components: {
			ExperienceLevelBadge
		},
		props: {
			minExperienceLevel: {
				type: Number,
				default: undefined
			},
			maxExperienceLevel: {
				type: Number,
				default: undefined
			},
			label: {
				type: Boolean,
				default: undefined
			}
		},
		computed: {
			getExperienceLevels () {
				const getDifference = () => {
					return this.maxExperienceLevel - this.minExperienceLevel + 1;
				};
				return Array.from({
					length: getDifference()
				}, (value, key) => {
					return this.minExperienceLevel + key;
				});
			},
			getLabel () {
				if (!this.label) {
					return false;
				}
				if (this.getExperienceLevels.length > 1) {
					return 'For levels';
				}
				return 'For level';
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='experience-level-chart'] {
		display:flex;
		align-items:center;
		[data-element='label'] {
			margin:auto rem(16) auto 0;
			font-size:rem(16);
			font-weight:700;
			color:$c-brand-grey;
		}
		[data-element='levels'] {
			display:flex;
			align-items:center;
		}
	}
</style>
