<template>
	<div
		data-component="live-or-solo-badge"
		:data-colour="getColour"
	>
		<span data-element="type">
			{{ getText }}
		</span>
		<span data-element="course-or-lesson">
			{{ courseOrLesson }}
		</span>
	</div>
</template>

<script>
	export default {
		props: {
			isLive: {
				type: [Boolean, undefined],
				default: undefined
			},
			courseOrLesson: {
				type: String,
				default: 'course'
			}
		},
		computed: {
			getText () {
				if (this.isLive) {
					return 'Live';
				}
				return 'Solo';
			},
			getColour () {
				if (this.isLive) {
					return 'red';
				}
				return 'blue';
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='live-or-solo-badge'] {
		display:inline-flex;
		align-items:center;
		justify-content:center;
		width:auto;
		height:rem(22);
		margin:0;
		padding:0 rem(8);
		font-size:rem(16);
		font-weight:700;
		color:$c-white;
		border-radius:4px;
		[data-element='type'] {
			display:inline;
			text-transform:uppercase;
			margin-right:0.25em;
		}
		[data-element='course-or-lesson'] {
			display:inline;
		}
		&[data-colour='blue'] {
			background-color:$c-brand-blue;
		}
		&[data-colour='red'] {
			background-color:$c-brand-red;
		}
	}
</style>
